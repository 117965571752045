import React, { Suspense, useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

const Header1 = React.lazy(() => import('../../layouts/header/Header1'));
const FooterStyle01 = React.lazy(() => import('../../layouts/footer/footer-style-01'));
const BackTop = React.lazy(() => import('../back-top'));

// Home
const HomeIndex = React.lazy(() => import('../../pages/home3/index'));

// Pages
const ContactUs = React.lazy(() => import('../../pages/contact/contact'));
const Error404 = React.lazy(() => import('../../pages/error404/error-page'));
const CrewForHire = React.lazy(() => import('../../pages/crew-for-hire'));
const TeamSingle = React.lazy(() => import('../../pages/team/team-single'))

// Equipment
const EquipmentCameras = React.lazy(() => import('../../pages/equipment/equipment-cameras'));
const EquipmentCamerasSingle = React.lazy(() => import('../../pages/equipment/equipment-cameras-details'));
const EquipmentLighting = React.lazy(() => import('../../pages/equipment/equipment-lighting'));
const EquipmentLightingSingle = React.lazy(() => import('../../pages/equipment/equipment-lighting-details'));
const EquipmentElectric = React.lazy(() => import('../../pages/equipment/equipment-electric'));
const EquipmentElectricSingle = React.lazy(() => import('../../pages/equipment/equipment-electric-details'));
const EquipmentSound = React.lazy(() => import('../../pages/equipment/equipment-sound'));
const EquipmentSoundSingle = React.lazy(() => import('../../pages/equipment/equipment-sound-details'));
const EquipmentGrip = React.lazy(() => import('../../pages/equipment/equipment-grip'));
const EquipmentGripSingle = React.lazy(() => import('../../pages/equipment/equipment-grip-details'));
const EquipmentExpendables = React.lazy(() => import('../../pages/equipment/equipment-expendables'));
const EquipmentExpendablesSingle = React.lazy(() => import('../../pages/equipment/equipment-expendables-details'));
const EquipmentList = React.lazy(() => import('../../pages/equipment/equipment-list'));

//TCs
const TCs = React.lazy(() => import('../../pages/tcs'));


function IsolatedRoutes(props) {
    const { cartItems, onAddEquipment } = props;
    return (
        <>
            <Routes>
                <Route path="/" element={<Header1 cartItems={cartItems} onAddEquipment={onAddEquipment} />} >
                    <Route index path="/" element={<HomeIndex />} />
                    <Route path="/*" element={<Error404 />} />
                    <Route path="/equipment-cameras" element={<EquipmentCameras onAddEquipment={onAddEquipment} cartItems={cartItems} />} />
                    <Route path="/equipment-cameras-details/:id" element={<EquipmentCamerasSingle onAddEquipment={onAddEquipment} cartItems={cartItems} />} />
                    <Route path="/equipment-lighting" element={<EquipmentLighting onAddEquipment={onAddEquipment} cartItems={cartItems} />} />
                    <Route path="/equipment-lighting-details/:id" element={<EquipmentLightingSingle onAddEquipment={onAddEquipment} cartItems={cartItems} />} />
                    <Route path="/equipment-sound" element={<EquipmentSound onAddEquipment={onAddEquipment} cartItems={cartItems} />} />
                    <Route path="/equipment-sound-details/:id" element={<EquipmentSoundSingle onAddEquipment={onAddEquipment} cartItems={cartItems} />} />
                    <Route path="/equipment-grip" element={<EquipmentGrip onAddEquipment={onAddEquipment} cartItems={cartItems} />} />
                    <Route path="/equipment-grip-details/:id" element={<EquipmentGripSingle onAddEquipment={onAddEquipment} cartItems={cartItems} />} />
                    <Route path="/equipment-electric" element={<EquipmentElectric onAddEquipment={onAddEquipment} cartItems={cartItems} />} />
                    <Route path="/equipment-electric-details/:id" element={<EquipmentElectricSingle onAddEquipment={onAddEquipment} cartItems={cartItems} />} />
                    <Route path="/equipment-expendables" element={<EquipmentExpendables onAddEquipment={onAddEquipment} cartItems={cartItems} />} />
                    <Route path="/equipment-expendables-details/:id" element={<EquipmentExpendablesSingle onAddEquipment={onAddEquipment} cartItems={cartItems} />} />
                    <Route path="/equipment-list" element={<EquipmentList onAddEquipment={onAddEquipment} cartItems={cartItems} />} />
                    <Route path="/tcs" element={<TCs />} />
                    <Route path="/contact" element={<ContactUs />} />
                    <Route path="/crew-for-hire" element={<CrewForHire />} />
                    <Route path="/team-single/:id" element={<TeamSingle />} />
                </Route>
            </Routes>
        </>
    );
}

export default IsolatedRoutes;